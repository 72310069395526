.serviceIcon {
  text-align: -webkit-right;
}
.serviceIcon-1 {
  width: fixed;
  transition: transform 0.2s;
  /* animation: serviceIcon-1 4s ease infinite; */
}
.singleCard:hover .serviceIcon-1 {
  transform: scale(1.2);
  /* @keyframes serviceIcon-1 {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  } */
}
