.welcome {
  background-color: initial;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#015dc7),
    to(#a200ff)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 12px;
  letter-spacing: 0.2em;

  display: inline-block;
}
.startBtn {
  background-image: linear-gradient(
    90deg,
    rgb(0, 93, 199) 0%,
    rgb(161, 0, 255) 100%
  );
}
.startBtn:hover {
  background: rgb(12, 67, 164);
  background: linear-gradient(
    58deg,
    rgba(12, 67, 164, 1) 65%,
    rgba(118, 15, 219, 1) 80%,
    rgba(154, 4, 252, 1) 95%,
    rgba(154, 4, 252, 1) 100%
  );
}

img.vert-move {
  position: inherit;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
